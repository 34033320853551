// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
// import { graphql } from 'gatsby';
// import { Provider } from 'mobx-react';

import {JSON_SPOTLIGHT} from '../constants/json';
import {STORE_JSON, STORE_UI} from '../constants/stores';
import JsonStore from '../stores/json-store';
import UiStore from '../stores/ui-store';
// import styles from './spotlight.module.scss';
// import Metadata from "../components/Metadata";
// import BlurUpImage from "../components/BlurUpImage";
// import getImageUrl from "../utils/get-image-url";
// import BlockHeader from "../components/blocks/BlockHeader";
// import Button from "../components/Button";
// import Divider from "../components/Divider";


type SpotlightProps = {
  json: JsonStore,
  ui: UiStore,
  data: any,
  location: any,
}

const MONTHS = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

@inject(STORE_JSON, STORE_UI)
@observer
class SpotlightPage extends React.Component<SpotlightProps, any> {

  ref: any;
  stores: any;

  constructor(props: SpotlightProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_SPOTLIGHT);
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props[STORE_JSON].getData(JSON_SPOTLIGHT, {
        contentType: 'spotlight',
        id: '6AHtUoLZHU2QKg8yuYsIk8'
      });
    }
  }

  parseDate(date: string): string { // 2017-08-07
    const dateArr: string[] = date.split('T')[0].split('-');
    return `${MONTHS[dateArr[1]]} ${dateArr[2]} ${dateArr[0]}`;
  }

  isCurrent(date: string): boolean {
    if (!date) return false;
    const dateArr: string[] = date.split('T')[0].split('-');
    let d: Date = new Date();
    if (d.getFullYear() > Number(dateArr[0])) {
      return false;
    }
    if (d.getFullYear() === Number(dateArr[0]) && d.getMonth() + 1 > Number(dateArr[1])) {
      return false;
    }
    if (d.getFullYear() === Number(dateArr[0]) && d.getMonth() + 1 === Number(dateArr[1]) && d.getDate() > Number(dateArr[2])) {
      return false;
    }
    return true;
  }

  render() {

    // const data = this.props[STORE_JSON].store.get(JSON_SPOTLIGHT) || this.props.data;

    return null;

    // return (
    //   <Provider {...this.stores}>
    //     <>
    //       <Metadata data={this.props.data.spotlight.metadata}
    //                 location={this.props.location}/>
    //       <div className={styles.container}>
    //         {data.spotlight && data.spotlight.tours.map((tour, index) => {
    //           return (
    //             <React.Fragment
    //               key={`${index}${tour.name}`}>
    //               <div className={styles.image}>
    //                 <div className={styles.ratio}>
    //                   <div className={styles.wrapper}>
    //                     <BlurUpImage src={getImageUrl(tour.poster)}
    //                                  srcSmall={getImageUrl(tour.posterSmall)}/>
    //                   </div>
    //                 </div>
    //               </div>
    //               {tour.blurb && <div className={styles.headerBlock}>
    //                 <BlockHeader markdown={tour.blurb}/>
    //               </div>}
    //               <div className={styles.dates}>
    //                 <div className={styles.dividers}>
    //                   <div className={styles.showDate}>
    //                     <div className={styles.verticalDivider}>

    //                       <div className={styles.line}>

    //                       </div>

    //                     </div>
    //                   </div>
    //                   {tour.showArtistInfo && <div className={styles.artist}>
    //                     <div className={styles.verticalDivider}>

    //                       <div className={styles.line}>

    //                       </div>

    //                     </div>
    //                   </div>}
    //                   <div className={styles.purchase}>
    //                     <div className={styles.verticalDivider}>

    //                       <div className={styles.line}>

    //                       </div>

    //                     </div>
    //                   </div>
    //                 </div>
    //                 {tour.dates.map((date, dateIndex) => {
    //                   return this.isCurrent(date.date) ? (
    //                     <div className={styles.date}
    //                          key={`${dateIndex}date`}>
    //                       <div className={styles.showDate}>
    //                         <span>
    //                           {this.parseDate(date.date)}
    //                         </span>
    //                       </div>
    //                       {tour.showArtistInfo && <div className={styles.artist}>
    //                         {date.artistName}
    //                       </div>}
    //                       <div className={styles.venue}>
    //                         <a href={date.venueUrl} target={`_blank`}>
    //                           {date.venueName}
    //                         </a>
    //                         {`- ${date.venueLocation}`}
    //                       </div>
    //                       <div className={styles.purchase}>
    //                         <Button type={'patch-large'}
    //                                 locked={date.soldOut}
    //                                 external={date.ticketsUrl}
    //                                 display={`${date.soldOut ?
    //                                   data.spotlight.soldOutText :
    //                                   data.spotlight.purchaseText}`}/>
    //                       </div>
    //                       <div className={styles.divider}>
    //                         <Divider color={'#ff6d64'}/>
    //                       </div>
    //                     </div>
    //                   ) : (<>

    //                   </>)
    //                 })}
    //               </div>
    //             </React.Fragment>
    //           )
    //         })}
    //       </div>
    //     </>
    //   </Provider>
    // )
  }
}

// export const query = graphql`
// query {
//   spotlight: contentfulSpotlight(contentful_id: {eq: "6AHtUoLZHU2QKg8yuYsIk8"}) {
//     metadata {
//       title
//       description
//       image {
//         file {
//           url
//         }
//       }
//       ogTitle
//       ogDescription
//       twitterCard
//     }
//     purchaseText
//     soldOutText
//     url
//     headerText
//     tours {
//       title
//       blurb {
//         markdown: blurb
//       }
//       poster {
//         file {
//           url
//         }
//       }
//       posterSmall {
//         file {
//           url
//         }
//       }
//       showArtistInfo
//       dates {
//         name
//         date
//         artistName
//         venueName
//         venueLocation
//         venueUrl
//         ticketsUrl
//         soldOut
//       }
//     }
//   }
// }`;

export default SpotlightPage;
